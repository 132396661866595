import React from "react"
import { Link } from "gatsby"
import Typed from 'react-typed'
import { window, document } from 'browser-monads';

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  state = {
    typeAnimFinished: false,
    bgWidth: 0,
    bgHeight: 0,
    scrollTop: 0
  }
  constructor(props) {
    super(props)
    this.portfolioOffset = 0
    this.portfolioOffsetBuffer = 0
  }
  componentWillMount() {
    this.scrollListener = document.addEventListener('scroll', this.handleDocumentScroll);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollListener)
  }
  handleDocumentScroll = (event) => {
    let scrollTop = 0
    let sy, d = document,
      r = d.documentElement,
      b = d.body;
    sy = r.scrollTop || b.scrollTop || 0;
    scrollTop = sy;

    let parallaxBg = this.refs.parallaxBg
    let portfolioItemTrig = this.refs.portfolioItemTrig
    let portfolioItem = this.refs.portfolioItem
    if (parallaxBg) {
      parallaxBg.style.transform = `translate3d(0, ${scrollTop / 5}px, 0)`
    }
    if (portfolioItemTrig && portfolioItem) {
      let offsetTop = portfolioItemTrig.offsetTop - scrollTop
      if (offsetTop < (window.innerHeight / 3)) {
        if (!portfolioItem.classList.contains('active')) {
          portfolioItem.classList.add('active')
        }
      } else {
        if (portfolioItem.classList.contains('active')) {
          portfolioItem.classList.remove('active')
        }
      }
    }

  }
  render() {
    const { typeAnimFinished } = this.state
    return (
      <Layout onUpdateSizes={this.handleUpdateSizes}>
        <SEO title="Home" />
        <div className="parallax-bg-wrapper" >
          <div ref='parallaxBg' className="parallax-bg" /* style={{transform: `translateY(${scrollTop / 5}px)`}} */></div>
        </div>
        <div className="banner-bg"></div>
        <div className="banner-rect-bg"></div>
        <div className="services-rect-bg"></div>
        <div className="tech-circle-bg"></div>
        <div className="content">
          <div id="banner">
            <h1><span className='company-name'>Di Sky Tech.</span>{!typeAnimFinished ? <Typed
              strings={[
                'We are graphic design experts.',
                'We are user experiance guru.',
                'We are programming wizards.',
                'We make technology dance!']}
              typeSpeed={50}
              backSpeed={40}
              backDelay={2000}
              onComplete={() => this.setState({ typeAnimFinished: true })} /> : 'We make technology dance!'}
            </h1>
           {/*  <iframe src={"/disco-ball.html"} /> */}
            <div className="scroll-icon bounce"></div>
          </div>
          <div id="services">
            <h2 className="section-title services-title">Services</h2>
            <div className="services-wrapper">
              <div className="service service-1">
                <h3>Graphic design</h3>
                <span>
                  Whether you’re looking for a spectacular new logo or some stunning flyers, we can make it happen.
                    </span>
              </div>
              <div className="service service-2">
                <h3>iOS and Android apps</h3>
                <span>
                  We provide iOS and Android mobile application development services so you can reach your customers on their favorite devices. We ensure that your app meets all the requirements we’ve agreed on, and we’ll help you add new features to your app once we launch it
                    </span>
              </div>
              <div className="service service-3">
                <h3>User Experience</h3>
                <span>
                  User Experience is a key part of every application. UX prototype represents the app’s structure and user flow. We help you to optimize user interaction with the app and to deliver tangible business results.
                    </span>
              </div>
              <div className="service service-4">
                <h3>DevOps</h3>
                <span>
                  We support and modify applications to cope with changes in the software environment, to meet new user requirements, and to ensure greater reliability.
                    </span>
              </div>
              <div className="service service-5">
                <h3>Web developm ent</h3>
                <span>
                  We deliver a wide range of custom web solutions for small and big businesses in different domains. We create web apps, services, and sites that accurately answer your expectations and satisfy the specific needs of your company
                    </span>
              </div>
              <div className="service service-6">
                <h3>Quality Assurance</h3>
                <span>
                  We use testing methodologies for software and follow extreme programming methodology to eliminate situations that may compromise product quality.
                    </span>
              </div>
            </div>
            <Link to="/quote" className="button">Get a Quote</Link>
          </div>
          <div id="technologies">
            <h2 className="section-title technologies-title">Technologies</h2>
            <div className="tech-image-wrapper">
              <object className="tech-image" type="image/svg+xml" data={require("../assets/images/tech.svg")} >
              </object>
            </div>
          </div>
          <div id="portfolio">
            <h2 className="section-title portfolio-title">Portfolio</h2>
            <div className="portfolio-wrapper">
              <img className="portfolio-item portfolio-1" src={require("../assets/images/port-1.png")} ref='portfolioItemTrig' />
              <img className="portfolio-item portfolio-2" src={require("../assets/images/port-3.png")} />
              <img className="portfolio-item portfolio-3" src={require("../assets/images/port-2.png")} ref='portfolioItem' />
              <img className="portfolio-item portfolio-4" src={require("../assets/images/port-4.png")} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
